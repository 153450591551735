import { baseApi } from '../baseApi';
import { UserAchievementNotViewedResponseDto, UserAchievementResponseType } from 'models/userachievement/userachievement';

const userAchievementApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserAchievements: builder.query<UserAchievementResponseType[], void>({
      query: () => ({
        url: '/user-achievement/me',
        method: 'GET',
      }),
      providesTags: ['UserAchievement'],
    }),
    getUserNotViewedAchievements: builder.query<UserAchievementNotViewedResponseDto[], void>({
      query: () => ({
        url: '/user-achievement/me/not-viewed',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetUserAchievementsQuery,
  useGetUserNotViewedAchievementsQuery
 } = userAchievementApi;
