import { ContentImage, ContentText } from "models/content/content";

export type ProgramEnrollment = {
  id: string;
  membershipId: string;
  programSlug: string;
  type: ProgramTypes;
  enrolledDate: string;
  completedDate: string;
}

export enum ProgramTypes {
  Exercise,
  Diet
}

export enum ProgramEnrollmentStatus {
  Enrolled = 'Enrolled',
  Completed = 'Completed',
  NotEnrolled = 'NotEnrolled'
}

export type ProgramEnrollmentRequest = {
  programSlug: string;
  programType: ProgramTypes;
}

export type ProgramEnrollmentResponseDto = {
  id: string;
  membershipId: string;
  programSlug: string;
  type: ProgramTypes;
  enrolledDate: string;
  completedDate: string;
  programDetails: ProgramDetailsResponseDto;
}

export type ProgramDetailsResponseDto = {
  title: ContentText;
  image: ContentImage;
}