import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import * as routes from 'router/routes';
import CategoryCard from 'components/UI/Cards/CategoryCard';
import Loader from 'components/UI/Loader';

import {
  localizationSelector,
  useGetExerciseProgramCategoryListQuery,
} from 'store';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { useAppSelector } from 'hooks/redux';
import { getImage } from 'utils/asset';

import { ActiveProgramContainer, CardList } from './styles';
import Heading, { Tag } from 'components/UI/Heading';
import { useGetUserProgramEnrollmentsQuery } from 'store/programEnrollmentService/programEnrollmentService';
import ActiveProgramCard from 'components/UI/Cards/ActiveProgramCard';
import { Gap } from 'styles';

const ExercisePrograms: FC = () => {
  const intl = useIntl();
  const getText = useLocalizedText();
  const { language } = useAppSelector(localizationSelector);

  // Hooks
  const { data, isLoading } = useGetExerciseProgramCategoryListQuery({
    language: language?.languageCode,
  });
  const { data: enrolledPrograms } = useGetUserProgramEnrollmentsQuery(
    language?.languageCode ?? 'sv'
  );

  const activeProgramsList = useMemo(() => {
    if (!enrolledPrograms) {
      return null;
    }
    const activePrograms = enrolledPrograms.filter((program) => program.completedDate === null);
    if (!activePrograms.length) {
      return null;
    }

    return (
      <ActiveProgramContainer>
        {activePrograms.map(({ programDetails, programSlug }, i) => {
          return (
            <ActiveProgramCard
              key={`exercise-program-${i}`}
              heading={
                intl.formatMessage({ id: 'pageExerciseProgramProgramText' })
              }
              title={
                getText(programDetails.title)
              }
              link={`${routes.EXERCISE_PROGRAM}/${programSlug}`}
              image={getImage(programDetails.image)}
            />
          );
        })}
      </ActiveProgramContainer>
    );
  }, [enrolledPrograms, getText, intl]);

  const completedProgramsList = useMemo(() => {
    if (!enrolledPrograms) {
      return null;
    }
    const completedPrograms = enrolledPrograms.filter((program) => program.completedDate !== null);
    if (!completedPrograms.length) {
      return null;
    }

    return (
      <ActiveProgramContainer>
        {completedPrograms.map(({ programDetails, programSlug }, i) => {
          return (
            <ActiveProgramCard
              key={`exercise-program-${i}`}
              heading={
                intl.formatMessage({ id: 'pageExerciseProgramProgramText' })
              }
              title={
                getText(programDetails.title)
              }
              link={`${routes.EXERCISE_PROGRAM}/${programSlug}`}
              image={getImage(programDetails.image)}
            />
          );
        })}
      </ActiveProgramContainer>
    );
  }
    , [enrolledPrograms, getText, intl]);

  if (isLoading) {
    return <Loader color="blue" padding />;
  }

  // No data
  if (!data || !data.length) {
    return null;
  }


  return (
    <>
      <CardList>
        {data.map(({ slug, title, image, numberOfExercisePrograms }, i) => {
          return (
            <CategoryCard
              key={`exercise-program-${i}`}
              title={getText(title)}
              byline={intl.formatMessage(
                {
                  id: 'pageExerciseProgramNoByline',
                  defaultMessage:
                    '{count, plural, =0 {no programs} one {# program} other {# programs}}',
                  description: 'No programs byline for exercises',
                },
                {
                  count: numberOfExercisePrograms,
                }
              )}
              link={`${routes.EXERCISE_PROGRAMS_CATEGORY}/${slug.current}`}
              image={getImage(image)}
            />
          );
        })}
      </CardList>

      <Gap />
      {activeProgramsList && (
        <>
          <Heading tag={Tag.H2}>
            {intl.formatMessage({ id: 'pageExercisesActiveProgramsTitle' })}
          </Heading>
          {activeProgramsList}
        </>
      )}

      <Gap />
      {completedProgramsList && (
        <>
          <Heading tag={Tag.H2}>
            {intl.formatMessage({ id: 'pageExercisesEndedProgramsTitle' })}
          </Heading>
          {completedProgramsList}
        </>
      )}
    </>
  );
};

export default ExercisePrograms;
