import styled from 'styled-components';
import { breakpoints } from 'styles';

export const AccomplishmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 100%;
`;

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 10px;
  width: 100%;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: ${breakpoints.s}px) {
    gap: 5px;
  }
`;

export const BadgeImage = styled.div`
  flex: 0 0 auto;
  cursor: pointer;

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;


    @media (max-width: ${breakpoints.m}px) {
      width: 100px;
      height: 100px;
    }


    @media (max-width: ${breakpoints.s}px) {
      width: 67px;
      height: 67px;
    }
  }
`;
export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  > button {
   padding: 0px;}
`;

export const IconContainer = styled.div`
  > svg {
   margin-top: 0.2rem;
   }
   `;

export const InnerButtonDetails = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-end;
   padding-top: 0.2em;
   color: ${({ theme }) => theme.colors.grayText};
`;