import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  localizationSelector,
  setLayoutHeader,
  useGetMembershipSettingsQuery,
  useGetTotalActivityAllCompaniesQuery,
  useGetTotalActivityMyCompanyQuery,
  useGetUserQuery,
} from 'store';
import { ContentWidth } from 'styles';
import TabMenu from 'components/UI/TabMenu';
import {
  BadgeformatDate,
  dateStringToISOString,
  getFirstDateOfMonth,
} from 'utils/date';
import { TabMenuItem } from 'models';
import HorizontalBadge from 'components/fragments/HorizontalBadge';
import BadgeDetails from 'components/fragments/BadgeDetails/BadgeDetails';
import LeadershipToggleButtons from 'components/UI/ToggleButton/LeadershipToggleButtons';
import { getImageUrl } from 'constants/urls';
import useAchievementsData from '../../hooks/useAchievementsData';
import footstep from '../../assets/RankIcon/footstep.png';
import wellrpoint from '../../assets/RankIcon/Wellr.png';
import * as routes from 'router/routes';

import {
  CardContainer,
  NumberContainer,
  ImageGrid,
  ImageCard,
  TitleRow,
  AllBadgesContainer,
  BackButton,
  BackButtonWrapper,
  Heading,
  ExpandButton,
  UserRankBadge,
  TopThreeContainer,
  LeadershipProfileWrapper,
  BackHeaderButton,
  BackButtonText,
} from './styles';

import LedarshipProfile from 'components/fragments/LedarshipProfile';
import { AchievementResponseType } from 'models/achievement/achievement';
import Loader from 'components/UI/Loader';
import defaultprofile from '../../assets/RankIcon/profile.svg';
import { getProfileImage } from 'utils/asset';
import { useAppSelector } from 'hooks/redux';
import Icon, { IconType } from 'components/UI/Icon';
import { useNavigate } from 'react-router-dom';
import TopListSettings from 'components/fragments/TopListSettings';

const Progress: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const settings = useGetMembershipSettingsQuery();
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const { language } = useAppSelector(localizationSelector);
  const { data: user } = useGetUserQuery();

  // States
  const [activeTab, setActiveTab] = useState<number | string>(1);
  const [activePage, setActivePage] = useState('global');
  const [viewAllMode, setViewAllMode] = useState<
    'myBadges' | 'lockedBadges' | 'badgeDetails' | null
  >(null);
  const [parentView, setParentView] = useState<
    'myBadges' | 'lockedBadges' | null
  >(null);
  const [isWellrSteps, setIsWellrSteps] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [profilesToShow, setProfilesToShow] = useState(10);

  const [LocalProfileImages, setLocalProfileImages] = useState<Record<string, string>>({});
  const [GlobalProfileImages, setGlobalProfileImages] = useState<Record<string, string>>({});

  const tabMenu: TabMenuItem[] = useMemo(
    () => [
      { id: 1, text: intl.formatMessage({ id: 'pageMyBadgesTitle' }) },
      { id: 2, text: intl.formatMessage({ id: 'pageMyLeaderboardTitle' }) },
    ],
    [intl]
  );

  // Hooks
  const {
    allLockedBadges,
    allUnlocked,
    loading,
  } = useAchievementsData();

  const { data: LocalUserlist } = useGetTotalActivityMyCompanyQuery({
    registeredFrom: dateStringToISOString(getFirstDateOfMonth(new Date())),
  });

  const { data: GlobalUserlist } = useGetTotalActivityAllCompaniesQuery({
    registeredFrom: dateStringToISOString(getFirstDateOfMonth(new Date())),
  });

  const backHeaderButton = useMemo(() => {
    if (viewAllMode === null || activeTab === 2) {
      return;
    }
    return (
      <BackHeaderButton
        onClick={() => setViewAllMode(null)}
      >
        <Icon type={IconType.ArrowLeft} color="black" />
        <BackButtonText>
          {intl.formatMessage({ id: 'backButton' })}
        </BackButtonText>
      </BackHeaderButton>
    );
  }, [activeTab, viewAllMode]);


  const userId = user?.id;

  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'tabExercisePrograms',
          defaultMessage: 'Programs',
          description: 'Tab item for exercise programs',
        }),
        icon: IconType.Back,
      })
    );
  }, [dispatch, intl]);


     const localProfileData = useMemo(() => {
    const sortedActivity = LocalUserlist?.userActivity
      ? [...LocalUserlist.userActivity]
          .sort((a, b) => isWellrSteps ? b.steps - a.steps : b.points - a.points)
          .map((profile, index) => ({
            ...profile,
            rank: (index + 1).toString() // Update rank based on new position
          }))
      : [];

    const LocaltopProfiles = sortedActivity.slice(0, 10);
    const remainingLocalProfiles = sortedActivity.slice(10);

    const MylocaluserRank = sortedActivity.findIndex(
      (activity) => activity.userId === userId
    ) + 1;

    const myLocaluserProfile = sortedActivity.find(
      (profile) => profile.userId === userId
    );

    return { LocaltopProfiles, remainingLocalProfiles, MylocaluserRank, myLocaluserProfile };
  }, [LocalUserlist, userId, isWellrSteps]);

  const globalProfileData = useMemo(() => {
    const sortedActivity = GlobalUserlist?.userActivity
      ? [...GlobalUserlist.userActivity]
          .sort((a, b) => isWellrSteps ? b.steps - a.steps : b.points - a.points)
          .map((profile, index) => ({
            ...profile,
            rank: (index + 1).toString() // Update rank based on new position
          }))
      : [];

    const GlobaltopProfiles = sortedActivity.slice(0, 10);
    const remainingGlobalProfiles = sortedActivity.slice(10);

    const MyglobaluserRank = sortedActivity.findIndex(
      (activity) => activity.userId === userId
    ) + 1;

    const myGlobaluserProfile = sortedActivity.find(
      (profile) => profile.userId === userId
    );

    return { GlobaltopProfiles, remainingGlobalProfiles, MyglobaluserRank, myGlobaluserProfile };
  }, [GlobalUserlist, userId, isWellrSteps]);


  useEffect(() => {
    if (activeTab === 2 || activePage === 'local') {
      setProfilesToShow(10);
      setShowMore(false);
      setIsWellrSteps(false);
    }
    if (activeTab === 1) {
      dispatch(
        setLayoutHeader({
          title: viewAllMode === null ? intl.formatMessage({ id: 'accomplishments' }) : '',
        })
      );
    }
  }, [dispatch, intl, activeTab, activePage, viewAllMode]);

  // Fetch Local profile images

useEffect(() => {
  const fetchImages = async () => {
    if (LocalUserlist && LocalUserlist.userActivity.length > 0) {
      const images = await Promise.all(
        LocalUserlist.userActivity.map(async (profile) => {
          if (!profile.profilePictureAssetId) {
            return [profile.userId, defaultprofile];
          }
          try {
            const imageUrl = await getProfileImage(
              profile.profilePictureAssetId,
              accessToken || ''
            );
            return [profile.userId, imageUrl ?? defaultprofile];
          } catch (error) {
            console.error('Failed to fetch image:', error);
            return [profile.userId, defaultprofile];
          }
        })
      );
      setLocalProfileImages(Object.fromEntries(images));
    }
  };

  fetchImages();
}, [LocalUserlist, accessToken]);


// Fetch Globa profile images
  useEffect(() => {
    const fetchImages = async () => {
      if (GlobalUserlist && GlobalUserlist.userActivity.length > 0) {
        const images = await Promise.all(
          GlobalUserlist.userActivity.map(async (profile) => {
            if (!profile.profilePictureAssetId) {
              return [profile.userId, defaultprofile];
            }
            try {
              const imageUrl = await getProfileImage(
                profile.profilePictureAssetId,
                accessToken || ''
              );
              return [profile.userId, imageUrl ?? defaultprofile];
             } catch (error) {
              console.error(
                'Failed to fetch image for profile ID:',
                profile.userId,
                error
              );
           return [profile.userId, defaultprofile];
            }
          })
        );
        setGlobalProfileImages(Object.fromEntries(images));
      }
    };

    fetchImages();
  }, [GlobalUserlist, accessToken]);


  const handlePageSwitch = (page: string) => {
    setActivePage(page);
  };

  const handleBadgeClick = (badge: any) => {

    navigate(routes.PROGRESS + '/' + badge.slug, { state: { badge } });
    setParentView(viewAllMode === 'badgeDetails' ? parentView : viewAllMode);
    setViewAllMode('badgeDetails');
  };


  const leaderboard = useMemo(() => {
    if (!GlobalUserlist || !LocalUserlist) {
      return <Loader />;
    }

    const handleShowMore = () => {
      setShowMore(true);
      setProfilesToShow(profilesToShow + 5);
    };

    if (settings.data?.showInToplistOverview === false) {
      return (
        <TopListSettings />
      )
    }

    else
      return (
        <Fragment>
          <LeadershipToggleButtons
            activePage={activePage}
            handlePageSwitch={handlePageSwitch}
            isWellrSteps={isWellrSteps}
            setIsWellrSteps={setIsWellrSteps}
          />


          <CardContainer>
            {activePage === 'global' && (
              <Fragment>
                <TopThreeContainer>
                  {globalProfileData.GlobaltopProfiles?.slice(0, 3).map((profile, index) => (
                    <LeadershipProfileWrapper
                      key={index}
                      className={
                        index === 0
                          ? 'profile-first'
                          : index === 1
                            ? 'profile-second'
                            : 'profile-third'
                      }
                    >
                      <LedarshipProfile
                        rank={profile.rank.toString()}
                        profileImage={
                          GlobalProfileImages[profile.userId] || defaultprofile
                        }
                        profileName={`${profile.firstName} ${profile.lastName}`}
                        company={profile.companyName}
                        wellrpoint={
                          isWellrSteps
                            ? profile.steps.toString()
                            : profile.points.toString()
                        }
                        wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                        rankSize={
                          profile.rank.toString() === '1'
                            ? 'first'
                            : profile.rank.toString() === '2'
                              ? 'second'
                              : 'third'
                        }
                        noBackground={true}
                      />
                    </LeadershipProfileWrapper>
                  ))}
                </TopThreeContainer>

                {globalProfileData.GlobaltopProfiles?.slice(3, profilesToShow).map(
                  (profile, index) => (
                    <LedarshipProfile
                      key={index + 3}
                      rank={profile.rank.toString()}
                      profileImage={
                        GlobalProfileImages[profile.userId ] || defaultprofile
                      }
                      profileName={`${profile.firstName} ${profile.lastName}`}
                      company={profile.companyName}
                      wellrpoint={
                        isWellrSteps
                          ? profile.steps.toString()
                          : profile.points.toString()
                      }
                      wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                      isFirst={index === 0}
                    />
                  )
                )}

                {globalProfileData.MyglobaluserRank > 10 && showMore && globalProfileData.myGlobaluserProfile && (
                  <Fragment>
                    <UserRankBadge>
                      <LedarshipProfile
                        rank={String(globalProfileData.myGlobaluserProfile.rank)}
                        profileImage={
                          GlobalProfileImages[localProfileData.MylocaluserRank] ||
                          defaultprofile
                        }
                        profileName={`${globalProfileData.myGlobaluserProfile.firstName} ${globalProfileData.myGlobaluserProfile.lastName}`}
                        company={globalProfileData.myGlobaluserProfile.companyName}
                        wellrpoint={
                          isWellrSteps
                            ? globalProfileData.myGlobaluserProfile.steps.toString()
                            : globalProfileData.myGlobaluserProfile.points.toString()
                        }
                        wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                        isFirst={profilesToShow > 10}
                      />
                    </UserRankBadge>
                  </Fragment>
                )}

                {showMore &&
                  globalProfileData.remainingGlobalProfiles
                    ?.slice(0, profilesToShow - 10)
                    .map((profile, index) => (
                      <LedarshipProfile
                        key={index + 10}
                        rank={profile.rank.toString()}
                        profileImage={
                          GlobalProfileImages[profile.userId] || defaultprofile
                        }
                        profileName={`${profile.firstName} ${profile.lastName}`}
                        company={profile.companyName}
                        wellrpoint={
                          isWellrSteps
                            ? profile.steps.toString()
                            : profile.points.toString()
                        }
                        wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                        isLast={
                          index ===
                          localProfileData.remainingLocalProfiles?.slice(
                            0,
                            profilesToShow - 10
                          ).length -
                          1
                        }
                      />
                    ))}

                {globalProfileData.remainingGlobalProfiles.length > profilesToShow - 10 && (
                  <BackButtonWrapper>
                    <ExpandButton onClick={handleShowMore}>
                      {intl.formatMessage({ id: 'showMoreButton' })}
                    </ExpandButton>
                  </BackButtonWrapper>
                )}
              </Fragment>
            )}

            {activePage === 'local' && (
              <Fragment>
                <TopThreeContainer>
                  {localProfileData.LocaltopProfiles?.slice(0, 3).map((profile, index) => (
                    <LeadershipProfileWrapper
                      key={index}
                      className={
                        index === 0
                          ? 'profile-first'
                          : index === 1
                            ? 'profile-second'
                            : 'profile-third'
                      }
                    >
                      <LedarshipProfile
                        rank={profile.rank.toString()}
                        profileImage={
                          LocalProfileImages[profile.userId] || defaultprofile
                        }
                        profileName={`${profile.firstName} ${profile.lastName}`}
                        company={profile.companyName}
                        wellrpoint={
                          isWellrSteps
                            ? profile.steps.toString()
                            : profile.points.toString()
                        }
                        wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                        rankSize={
                          profile.rank.toString() === '1'
                            ? 'first'
                            : profile.rank.toString() === '2'
                              ? 'second'
                              : 'third'
                        }
                        noBackground={true}
                      />
                    </LeadershipProfileWrapper>
                  ))}
                </TopThreeContainer>

                {localProfileData.LocaltopProfiles?.slice(3, profilesToShow).map(
                  (profile, index) => (
                    <LedarshipProfile
                      key={index + 3}
                      rank={profile.rank.toString()}
                      profileImage={
                        LocalProfileImages[profile.userId] || defaultprofile
                      }
                      profileName={`${profile.firstName} ${profile.lastName}`}
                      company={profile.companyName}
                      wellrpoint={
                        isWellrSteps
                          ? profile.steps.toString()
                          : profile.points.toString()
                      }
                      wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                      isFirst={index === 0}
                    />
                  )
                )}

                {localProfileData.MylocaluserRank > 10 && showMore && localProfileData.myLocaluserProfile && (
                  <Fragment>
                    <UserRankBadge>
                      <LedarshipProfile
                        rank={String(localProfileData.myLocaluserProfile.rank)}
                        profileImage={
                          LocalProfileImages[localProfileData.myLocaluserProfile.userId] ||
                          defaultprofile
                        }
                        profileName={`${localProfileData.myLocaluserProfile.firstName} ${localProfileData.myLocaluserProfile.lastName}`}
                        company={localProfileData.myLocaluserProfile.companyName}
                        wellrpoint={
                          isWellrSteps
                            ? localProfileData.myLocaluserProfile.steps.toString()
                            : localProfileData.myLocaluserProfile.points.toString()
                        }
                        wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                        isFirst={profilesToShow > 10}
                      />
                    </UserRankBadge>
                  </Fragment>
                )}

                {showMore &&
                  localProfileData.remainingLocalProfiles
                    ?.slice(0, profilesToShow - 10)
                    .map((profile, index) => (
                      <LedarshipProfile
                        key={index + 10}
                        rank={profile.rank.toString()}
                        profileImage={
                          LocalProfileImages[profile.userId] || defaultprofile
                        }
                        profileName={`${profile.firstName} ${profile.lastName}`}
                        company={profile.companyName}
                        wellrpoint={
                          isWellrSteps
                            ? profile.steps.toString()
                            : profile.points.toString()
                        }
                        wellrpointLogo={isWellrSteps ? footstep : wellrpoint}
                        isLast={
                          index ===
                          localProfileData.remainingLocalProfiles?.slice(
                            0,
                            profilesToShow - 10
                          ).length -
                          1
                        }
                      />
                    ))}

                {localProfileData.remainingLocalProfiles.length > profilesToShow - 10 && (
                  <BackButtonWrapper>
                    <ExpandButton onClick={handleShowMore}>
                      {intl.formatMessage({ id: 'showMoreButton' })}
                    </ExpandButton>
                  </BackButtonWrapper>
                )}
              </Fragment>
            )}
          </CardContainer>
        </Fragment>
      );
  }, [GlobalUserlist, LocalUserlist, settings.data?.showInToplistOverview, activePage, isWellrSteps, globalProfileData.GlobaltopProfiles, globalProfileData.MyglobaluserRank, globalProfileData.myGlobaluserProfile, globalProfileData.remainingGlobalProfiles, profilesToShow, showMore, GlobalProfileImages, localProfileData.MylocaluserRank, localProfileData.LocaltopProfiles, localProfileData.myLocaluserProfile, localProfileData.remainingLocalProfiles, LocalProfileImages, intl]);

  if (!accessToken) {
    return <Loader />;
  }

  if (loading) return <Loader />;

  return (
    <ContentWidth>
      {backHeaderButton}
      <Fragment>

        <TabMenu
          menuId="my-overview"
          menu={tabMenu}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        {activeTab === 1 && (
          <CardContainer>
            {viewAllMode === 'myBadges' && (
              <AllBadgesContainer>
                {allUnlocked && allUnlocked.length > 0 ? (
                  <>
                    <Heading>{intl.formatMessage({ id: 'allMyBadges' })}</Heading>
                    {allUnlocked
                      .sort(
                        (a, b) =>
                          new Date(a.completed).getTime() -
                          new Date(b.completed).getTime()
                      )
                      .map((badge: AchievementResponseType) => {
                        const imageRef = badge.Image?.asset._ref;
                        const imageUrl = getImageUrl(imageRef);

                        return (
                          <HorizontalBadge
                            key={badge.slug}
                            image={imageUrl}
                            title={badge.title[language?.languageCode ?? 'en'] || 'Unknown Title'}
                            description={
                              badge.description[language?.languageCode ?? 'en'] ||
                              'No description available'
                            }
                            onClick={() => handleBadgeClick(badge)}
                            isLocked={
                              badge.amountToComplete !== null || badge.isLocked
                            }
                          />
                        );
                      })}
                  </>
                ) : (
                  <AllBadgesContainer>
                    <Heading>{intl.formatMessage({ id: 'noUnlockedBadges' })}</Heading>
                  </AllBadgesContainer>
                )}
              </AllBadgesContainer>
            )}

            {viewAllMode === 'lockedBadges' && (
              <AllBadgesContainer>
                {allLockedBadges && allLockedBadges.length > 0 ? (
                  <>
                    <Heading>{intl.formatMessage({ id: 'allLockedBadges' })}</Heading>
                    {allLockedBadges
                      .map((badge: AchievementResponseType) => {
                        const imageRef = badge.Image?.asset._ref;
                        const imageUrl = getImageUrl(imageRef);
                        return (
                          <HorizontalBadge
                            key={badge.slug}
                            image={imageUrl}
                            title={badge.title[language?.languageCode ?? 'en'] || 'Unknown Title'}
                            description={`${intl.formatMessage({ id: 'pageProgressCompleteChallengeBadge' })} "${badge.title[language?.languageCode ?? 'en']}"`}
                            onClick={() => handleBadgeClick(badge)}
                            isLocked={badge.isLocked}
                          />
                        );
                      })}
                  </>
                ) : (
                  <AllBadgesContainer>
                    <Heading>
                      {intl.formatMessage({ id: 'noMoreLockedBadges' })}
                    </Heading>
                  </AllBadgesContainer>
                )}

                <BackButtonWrapper>
                  <BackButton
                    className="link"
                    onClick={() => setViewAllMode(null)}
                  >
                    {intl.formatMessage({ id: 'backButton' })}
                  </BackButton>
                </BackButtonWrapper>
              </AllBadgesContainer>
            )}

            {viewAllMode === 'badgeDetails' && (
              <BadgeDetails />
            )}

            {!viewAllMode && (
              <Fragment>
                <NumberContainer>
                  <span className="number">{allUnlocked?.length ?? 0}</span>
                  <p className="badge-text">
                    {intl.formatMessage({ id: 'pageBadgesUnlocked' })}
                  </p>
                </NumberContainer>

                <ImageGrid>
                  {allUnlocked
                    ?.sort(
                      (a, b) =>
                        new Date(b.completed).getTime() -
                        new Date(a.completed).getTime()
                    )
                    .slice(0, 3)
                    .map((badge) => (
                      <ImageCard
                      key={badge.slug}
                      onClick={() => handleBadgeClick(badge)}>
                        <img
                          src={getImageUrl(badge.Image?.asset?._ref)}
                          alt={`Badge ${badge.title[language?.languageCode ?? 'en']}`}
                        />
                        <p className="image-date">
                          {BadgeformatDate(badge.completed)}
                        </p>
                        <p className="image-title">{badge.title[language?.languageCode ?? 'en']}</p>
                      </ImageCard>
                    ))}
                </ImageGrid>

                <TitleRow>
                  <h4 className="title">
                    {intl.formatMessage({ id: 'pageMyUnlockedBadgesTitle' })}
                  </h4>
                  <button
                    className="link"
                    onClick={() => setViewAllMode('myBadges')}
                  >
                    {intl.formatMessage({ id: 'pageSeeAllUnlockedBadges' })}
                  </button>
                </TitleRow>

                {allUnlocked && allUnlocked.length > 0 ? (
                  allUnlocked
                    .sort(
                      (a, b) =>
                        new Date(b.completed).getTime() -
                        new Date(a.completed).getTime()
                    )
                    .slice(0, 4)
                    .map((badge: AchievementResponseType) => {
                      const imageRef = badge.Image?.asset._ref;
                      const imageUrl = getImageUrl(imageRef);
                      return (
                        <HorizontalBadge
                          key={badge.slug}
                          image={imageUrl}
                          title={badge.title[language?.languageCode ?? 'en'] || 'Title Unavailable'}
                          description={
                            badge.description[language?.languageCode ?? 'en'] || 'Description Unavailable'
                          }
                          onClick={() => handleBadgeClick(badge)}
                          isLocked={
                            badge.amountToComplete !== null || badge.isLocked
                          }
                        />
                      );
                    })
                ) : (
                  <AllBadgesContainer>
                    <Heading>{intl.formatMessage({ id: 'noUnlockedBadges' })}</Heading>
                  </AllBadgesContainer>
                )}
                <TitleRow>
                  <h4>
                    {intl.formatMessage({ id: 'pageMyLockedBadgesTitle' })}
                  </h4>
                  <button
                    className="link"
                    onClick={() => setViewAllMode('lockedBadges')}
                  >
                    {intl.formatMessage({ id: 'pageSeeAllLockedBadges' })}
                  </button>
                </TitleRow>

                {allLockedBadges && allLockedBadges.length > 0 ? (
                  allLockedBadges
                    .slice(-4)
                    .map((badge: AchievementResponseType) => {
                      const imageRef = badge.Image?.asset._ref;
                      const imageUrl = getImageUrl(imageRef);
                      return (
                        <HorizontalBadge
                          key={badge.slug}
                          image={imageUrl}
                          title={badge.title[language?.languageCode ?? 'en'] || 'Title Unavailable'}
                          description={`${intl.formatMessage({ id: 'pageProgressCompleteChallengeBadge' })}: ${badge.title[language?.languageCode ?? 'en']}`}
                          onClick={() => handleBadgeClick(badge)}
                          isLocked={
                            badge.amountToComplete !== null || badge.isLocked
                          }
                        />
                      );
                    })
                ) : (
                  <AllBadgesContainer>
                    <Heading>
                      {intl.formatMessage({ id: 'noMoreLockedBadges' })}
                    </Heading>
                  </AllBadgesContainer>
                )}
              </Fragment>
            )}
          </CardContainer>
        )}
        {activeTab === 2 && leaderboard}
      </Fragment>
    </ContentWidth>
  );
};

export default Progress;
