import { FC, Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  useCompleteExerciseProgramStepMutation,
  useDeleteExerciseProgramStepMutation,
  useGetCompletedExerciseEntitiesQuery,
} from 'store';

import { ExerciseDescriptionPair, ExerciseProgramStep } from 'models';
import { Gap, SmallGap } from 'styles';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { getImage } from 'utils/asset';
import * as routes from 'router/routes';

import RichText from 'components/fragments/RichText';
import ExpandableCard from 'components/UI/Cards/ExpandableCard';
import Heading, { Tag } from 'components/UI/Heading';
import Loader from 'components/UI/Loader';
import CategoryCard from 'components/UI/Cards/CategoryCard';

import { CardList, CardItem } from './styles';
import Button from 'components/UI/Button';
import { useCompleteProgramMutation, useDeleteProgramEnrollmentMutation, useGetEnrollmentStatusQuery, useStartProgramMutation } from 'store/programEnrollmentService/programEnrollmentService';
import { ProgramEnrollmentStatus, ProgramTypes } from 'models/programEnrollment/programEnrollment';
import { confetti } from 'tsparticles-confetti';
import toast from 'react-hot-toast';

type Props = {
  slug: string;
  exerciseDescriptionPairs: ExerciseDescriptionPair[];
  exerciseProgramSteps: ExerciseProgramStep[];
};

const ExcerciseProgramProgress: FC<Props> = ({
  slug,
  exerciseDescriptionPairs,
  exerciseProgramSteps,
}) => {

  // Hooks
  const getText = useLocalizedText();
  const intl = useIntl();

  const { data: completedExerciesData, isLoading } =
    useGetCompletedExerciseEntitiesQuery({
      exerciseProgramSlug: slug as string,
    });

  const { data: enrollmentStatus, refetch } = useGetEnrollmentStatusQuery(slug);
  const isEnrolled = enrollmentStatus === ProgramEnrollmentStatus.Enrolled;
  const isCompleted = enrollmentStatus === ProgramEnrollmentStatus.Completed;

  const [checkProgramStep] = useCompleteExerciseProgramStepMutation();
  const [uncheckProgramStep] = useDeleteExerciseProgramStepMutation();

  const [startProgram] = useStartProgramMutation();
  const [deleteProgram] = useDeleteProgramEnrollmentMutation();
  const [completeProgram] = useCompleteProgramMutation();

  const checkedPairs = useMemo(() => {
    if (completedExerciesData?.completedExercises != null) {
      return completedExerciesData.completedExercises.map((pair) => pair.key);
    }
    return [];
  }, [completedExerciesData?.completedExercises]);

  const checkedSteps = useMemo(() => {
    if (completedExerciesData?.completedSteps != null) {
      return completedExerciesData.completedSteps.map((step) => step.key);
    }
    return [];
  }, [completedExerciesData?.completedSteps]);

  const toggleStep = useCallback(
    (stepKey: string, isChecked: boolean) => {
      if (isChecked) {
        uncheckProgramStep({ exerciseProgramSlug: slug as string, stepKey });
      } else {
        checkProgramStep({ exerciseProgramSlug: slug as string, stepKey });
      }
    },
    [checkProgramStep, slug, uncheckProgramStep]
  );

  const onClickStart = useCallback(
    async () => {
      await startProgram({ programSlug: slug, programType: ProgramTypes.Exercise });
      refetch();
    },
    [refetch, slug, startProgram]
  );

  const onClickCancel = useCallback(
    async () => {
      await deleteProgram(slug);
      refetch();
    },
    [deleteProgram, slug, refetch]
  );

  const onClickRestart = useCallback(
    async () => {
      await deleteProgram(slug);
      await startProgram({ programSlug: slug, programType: ProgramTypes.Exercise });
      refetch();
    },
    [deleteProgram, slug, startProgram, refetch]
  );

  const onClickComplete = useCallback(
    async () => {
      await completeProgram(slug);
      refetch();

      window.scrollTo(0, 0);
      confetti({
        spread: 360,
        ticks: 50,
        gravity: 0,
        decay: 0.94,
        startVelocity: 30,
        colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
        particleCount: 80,
        scalar: 1.2,
        shapes: ['star'],
      });
      toast.success(
        intl.formatMessage({
          id: 'exerciseProgramCompleted',
          defaultMessage: 'Well done! You have completed an exercise program!',
          description: 'Toast message for exercise program completed',
        })
      );
    },
    [completeProgram, slug, refetch, intl]
  );

  const renderMarkButton = useMemo(() =>
    ({ allStepsCompleted, enrolled }: { allStepsCompleted: boolean; enrolled: boolean }) => {
      if (isLoading) {
        return <Loader color="blue" />;
      }

      if (isCompleted) {
        return (
          <Button
            background="white"
            color="pink"
            border="pink"
            width='100%'
            onClick={() => onClickRestart()}
          >
            <FormattedMessage
              id="pageExerciseProgramRestartProgramButton"
              defaultMessage="Restart program"
              description="Restart program button for exercise program"
            />
          </Button>
        );
      }

      if (allStepsCompleted && enrolled) {
        return (
          <Button
            background="blue"
            color="white"
            border="blue"
            width='100%'
            onClick={() => onClickComplete()}
          >
            <FormattedMessage
              id="pageMeasureProgramStepMarkDone"
              defaultMessage="Mark as done"
              description="Mark as done button for exercise program step"
            />
          </Button>
        );
      }
      else if (enrolled) {
        return (
          <Button
            background="white"
            color="pink"
            border="pink"
            width='100%'
            onClick={() => onClickCancel()}
          >
            <FormattedMessage
              id="pageExerciseProgramCancelProgramButton"
              defaultMessage="Cancel program"
              description="Cancel program button for exercise program"
            />
          </Button>
        );
      }

      return (
        <Button
          background="blue"
          color="white"
          border="blue"
          width='100%'
          onClick={() => onClickStart()}
        >
          <FormattedMessage
            id="pageExerciseProgramStartProgramButton"
            defaultMessage="Start program"
            description="Start program button for exercise program"
          />
        </Button>
      );
    }, [isLoading, isCompleted, onClickRestart, onClickComplete, onClickCancel, onClickStart]
  );

  if (isLoading) {
    return <Loader color="blue" padding />;
  }


  if (!completedExerciesData) {
    return null;
  }

  return (
    <>
      {exerciseDescriptionPairs.length > 0 && (
        <Fragment>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="pageExerciseProgramSectionTitleExercises"
              defaultMessage="Exercises"
              description="Page section title for exercises in exercise program"
            />
          </Heading>
          <CardList>
            {exerciseDescriptionPairs.map((pair, i) => {
              if (!pair.exercise) {
                return null;
              }
              const { title, image, slug: exerciseSlug } = pair.exercise;
              return (
                <CategoryCard
                  key={`exercise-${i}`}
                  title={`${getText(pair.title)} - ${getText(title)}`}
                  link={`${routes.EXERCISES}/${exerciseSlug.current}`}
                  linkOptions={{
                    state: {
                      exerciseKey: pair.key,
                      exerciseProgramSlug: slug,
                    },
                  }}
                  image={getImage(image)}
                  isCompleted={checkedPairs.includes(pair.key)}
                />
              );
            })}
          </CardList>
          <Gap />
        </Fragment>
      )}
      {exerciseProgramSteps.length > 0 && (
        <Fragment>
          <Heading tag={Tag.H4}>
            <FormattedMessage
              id="pageExerciseProgramSectionTitleProgram"
              defaultMessage="Program"
              description="Page section title for program in exercise program"
            />
          </Heading>
          {renderMarkButton({ allStepsCompleted: exerciseProgramSteps.length === completedExerciesData.completedSteps?.length, enrolled: isEnrolled ?? false })}
          <SmallGap />
          <CardList>
            {exerciseProgramSteps.map((step) => {
              return (
                <ExpandableCard
                  key={step.key}
                  id={step.key}
                  title={getText(step.title)}
                  onCheck={toggleStep}
                  isChecked={checkedSteps.includes(step.key) && (isEnrolled || isCompleted)}
                  lockedButClickable={!isEnrolled}
                >
                  <CardItem>
                    <RichText>{step.content}</RichText>
                  </CardItem>
                </ExpandableCard>
              );
            })}
          </CardList>
        </Fragment>
      )}
    </>
  );
};

export default ExcerciseProgramProgress;
