import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import * as routes from 'router/routes';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  localizationSelector,
  setLayoutHeader,
  useGetMyPersonalHealthQuery,
} from 'store';

import HealthWheel from 'components/fragments/HealthWheel';
import ListMenu from 'components/UI/ListMenu';
import { IconType } from 'components/UI/Icon';
import Heading, { Tag } from 'components/UI/Heading';

import { Gap, SmallGap } from 'styles';
import ActivitiesMonthly from 'components/fragments/ActivitiesMonthly';
import { getTimezoneOffset } from 'utils/date';
import HighlightCard from 'components/UI/Cards/HighlightCard';
import { StatsCardList } from './styles';
import AccomplishmentsOverview from 'components/fragments/AccomplishmentsOverview';

const MyHealth: FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(localizationSelector);

  const { data } = useGetMyPersonalHealthQuery({
    minutesOffsetFromUtc: getTimezoneOffset(),
  });

  // Set header
  useEffect(() => {
    dispatch(
      setLayoutHeader({
        title: intl.formatMessage({
          id: 'myHealthTitle',
          defaultMessage: 'My health',
          description: 'Menu item for my health',
        }),
        inverted: true,
      })
    );
  }, [dispatch, intl]);

  const cards = useMemo(() => {
    if (!data) {
      return null;
    }
    return (
      <>
        <HighlightCard
          title={intl.formatMessage({
            id: 'pageMyHealthPinkCardTitle',
            defaultMessage: 'Steps in the last 7 days',
            description: 'Card title for pink card on admin overview page',
          })}
          background="pink"
          icon={IconType.Footprints}
          iconColor="pinkDark"
          description={intl.formatMessage(
            {
              id: 'pageMyHealthPinkCardDescription',
              defaultMessage: 'Total number of steps: {totalSteps}',
              description:
                'Card description for pink card on admin overview page',
            },
            { totalSteps: data.totalSteps }
          )}
        >
          {data.stepsLastSevenDays}
        </HighlightCard>
        <StatsCardList>
          <HighlightCard
            title={intl.formatMessage({
              id: 'pageMyHealthOverviewHealthPlansCardTitle',
              defaultMessage: 'Completed health plans',
              description: 'Card title for how many health plans completed',
            })}
            background="purple"
            icon={IconType.Health}
            iconColor="purpleDark"
          >
            {data.completedHealthPlans}
          </HighlightCard>
          <HighlightCard
            title={intl.formatMessage({
              id: 'pageMyHealthOverviewWorkedStandingCardTitle',
              defaultMessage: 'Activity minutes in the last 7 days',
              description: 'Card title for how many minutes worked standing',
            })}
            background="success"
            icon={IconType.Exercise}
            iconColor="successDark"
          >
            {data.minutesLastSevenDays}
          </HighlightCard>
        </StatsCardList>
      </>
    );
  }, [data, intl]);

  if (!language) {
    return null;
  }

  return (
    <Fragment>
      <HealthWheel language={language.languageCode} />
      <ListMenu
        menu={[
          {
            id: 1,
            text: intl.formatMessage({
              id: 'menuAboutMyHealthReport',
              defaultMessage: 'My health report',
              description: 'Menu item for about my health',
            }),
            link: `${routes.MY_HEALTH_REPORT}`,
            icon: IconType.HealthReport,
          },
        ]}
      />
      <div style={{marginTop: '0.5rem'}}></div>
      <ListMenu
        menu={[
          {
            id: 1,
            text: intl.formatMessage({
              id: 'menuAboutMyHealth',
              defaultMessage: 'How "My health" works',
              description: 'Menu item for about my health',
            }),
            link: `${routes.INSPIRATION_ARTICLE}/how-my-health-works`,
            icon: IconType.Inspiration,
          },
        ]}
      />
      <AccomplishmentsOverview />
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageProfileSectionTitleActivityLog"
          defaultMessage="Activity log"
          description="Page section title for activity log"
        />
      </Heading>
      <ActivitiesMonthly />
      <Gap />
      {cards}
    </Fragment>
  );
};

export default MyHealth;
