import { ProgramEnrollmentRequest, ProgramEnrollmentResponseDto, ProgramEnrollmentStatus } from 'models/programEnrollment/programEnrollment';
import { baseApi } from '../baseApi';

const programEnrollmentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    startProgram: builder.mutation<void, ProgramEnrollmentRequest>({
      query: ({ programSlug, programType }) => ({
        url: `/program-enrollment/start?programSlug=${programSlug}&programType=${programType}`,
        method: 'POST',
      }),
      invalidatesTags: ['ProgramEnrollment'],
    }),
    deleteProgramEnrollment: builder.mutation<boolean, string>({
      query: (programSlug) => ({
        url: `/program-enrollment/${programSlug}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ProgramEnrollment'],
    }),
    getEnrollmentStatus: builder.query<ProgramEnrollmentStatus, string>({
      query: (programSlug) => `/program-enrollment/enrollment-status/${programSlug}`,
    }),
    completeProgram: builder.mutation<void, string>({
      query: (programSlug) => ({
        url: `/program-enrollment/complete/${programSlug}`,
        method: 'PUT',
      }),
      invalidatesTags: ['ProgramEnrollment'],
    }),
    getProgramEnrollment: builder.query<ProgramEnrollmentResponseDto, string>({
      query: (programSlug) => `/program-enrollment/${programSlug}`,
    }),
    getUserProgramEnrollments: builder.query<ProgramEnrollmentResponseDto[], string>({
      query: (languageCode) => '/program-enrollment?languageCode=' + languageCode,
      providesTags: ['ProgramEnrollment'],
    }),
  }),
});

export const {
  useStartProgramMutation,
  useDeleteProgramEnrollmentMutation,
  useGetEnrollmentStatusQuery,
  useCompleteProgramMutation,
  useGetProgramEnrollmentQuery,
  useGetUserProgramEnrollmentsQuery
} = programEnrollmentApi;

